.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::selection {
  background: #cca300;
  color: #222222;
}
* {
  -webkit-tap-highlight-color: rgba(255, 204, 0, 0.1);
  box-sizing: border-box;
}
@media (min-width: 61.875rem) {
  * {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
  }
}
main {
  display: block;
}
html {
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((0.9375rem + 0.0625 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.9375rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #222222;
  font-family: "Kanit", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 300;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 160rem;
  position: relative;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(0.9375rem + 0.0625 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  body {
    font-size: 1rem;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
.style_h1,
.style_h2,
.style_h3,
.style_h4,
.style_h5,
.style_h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1,
.style_h1 {
  letter-spacing: calc((1.3125rem + 1.9375 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.3125rem;
  color: #222222;
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  line-height: 1.35;
  margin: 0 0 0.75em;
}
@media (min-width: 20rem) {
  h1,
  .style_h1 {
    font-size: calc(1.3125rem + 1.9375 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h1,
  .style_h1 {
    font-size: 3.25rem;
  }
}
h2,
.style_h2 {
  letter-spacing: calc((1.375rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.375rem;
  color: #222222;
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h2,
  .style_h2 {
    font-size: calc(1.375rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h2,
  .style_h2 {
    font-size: 1.5rem;
  }
}
h3,
.style_h3 {
  letter-spacing: calc((1.25rem + -0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.25rem;
  color: #222222;
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h3,
  .style_h3 {
    font-size: calc(1.25rem + -0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h3,
  .style_h3 {
    font-size: 1.125rem;
  }
}
h4,
.style_h4 {
  letter-spacing: calc((1rem + 0 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1rem;
  color: #222222;
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  line-height: 1.35;
  margin: 0 0 0.75em;
}
@media (min-width: 20rem) {
  h4,
  .style_h4 {
    font-size: calc(1rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h4,
  .style_h4 {
    font-size: 1rem;
  }
}
h5,
.style_h5 {
  letter-spacing: calc((0.9375rem + 0 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.9375rem;
  color: #222222;
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h5,
  .style_h5 {
    font-size: calc(0.9375rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h5,
  .style_h5 {
    font-size: 0.9375rem;
  }
}
h6,
.style_h6 {
  letter-spacing: calc((0.875rem + 0 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.875rem;
  color: #222222;
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h6,
  .style_h6 {
    font-size: calc(0.875rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h6,
  .style_h6 {
    font-size: 0.875rem;
  }
}
p {
  margin: 0 0 0.75em;
}
a {
  color: #222222;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #222222;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(255, 204, 0, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"] {
  display: block;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
}
a[tabindex="-1"][id]:after {
  content: "";
}
a.touch_link {
  color: #222222;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #FFCC00;
  display: inline-block;
  margin-bottom: 0.375em;
  outline-offset: 0;
  padding: 0;
  text-decoration: underline;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #222222;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(255, 204, 0, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 600;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.75em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.5em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.5em;
}
table {
  border-bottom: 0.0625rem solid #a2a2a2;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 600;
  text-align: left;
}
td,
th {
  border-top: 0.0625rem solid #a2a2a2;
  padding: 0.5em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
tr:hover td,
tr:hover th {
  background: #bbbbbb;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.75em;
  padding: 0;
}
ol > li {
  padding-left: 1.5em;
  position: relative;
}
ol > li:before {
  color: #FFCC00;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 600;
  left: 0;
  line-height: 1.5;
  position: absolute;
  text-align: right;
  width: 1.125em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url("../images/list_style_image_rectangle.svg") !important;
  margin: 0 0 0.75em;
  padding-left: 1.125em;
}
ul > li {
  padding-left: 0.375em;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list dl {
  border-bottom: 0.0625rem solid #a2a2a2;
  margin: 0.75em 0;
}
@media (min-width: 35rem) {
  .defined_list dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list dt {
  border-top: 0.0625rem solid #a2a2a2;
  font-weight: 600;
  padding: 0.75em 0;
}
@media (min-width: 35rem) {
  .defined_list dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.75em 0.75em 0.75em 0;
  }
}
.defined_list dd {
  margin: 0;
}
@media (min-width: 35rem) {
  .defined_list dd {
    border-top: 0.0625rem solid #a2a2a2;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.75em 0.5em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none !important;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.5em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.5em;
}
figure.floatright {
  float: right;
  margin-left: 1.5em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.375em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-weight: 300;
  letter-spacing: calc((0.9375rem + 0.0625 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.9375rem;
}
@media (min-width: 20rem) {
  input,
  select,
  textarea,
  button {
    font-size: calc(0.9375rem + 0.0625 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  input,
  select,
  textarea,
  button {
    font-size: 1rem;
  }
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #222222;
  box-shadow: 0 0 0 62.5rem #fff inset, 0 0 0.1875rem #FFCC00 inset, 0 0 0 0.1875rem rgba(255, 204, 0, 0.2) !important;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
::-webkit-input-placeholder {
  color: #626262;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #626262;
  -ms-user-select: none;
      user-select: none;
}
::-ms-input-placeholder {
  color: #626262;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #626262;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #FFCC00;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #FFCC00;
  color: #fff;
  display: none;
  font-weight: 600;
  margin-right: 0.375em;
  padding: 0 0.375em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.375rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.375rem;
  color: #222222;
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding: 0.75em 0 0;
  width: 100%;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.375rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .legend {
    font-size: 1.5rem;
  }
}
.legend .label {
  margin-bottom: 0;
}
.label {
  display: inline-block;
  margin-bottom: 0.375em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.0625rem solid #a2a2a2;
  border-radius: 0;
  box-shadow: 0 0 0 #a2a2a2 inset;
  color: #222222;
  margin: 0 0 1em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #FFCC00;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #FFCC00;
  box-shadow: 0 0 0.1875rem #FFCC00 inset, 0 0 0 0.1875rem rgba(255, 204, 0, 0.2);
  color: #222222;
}
.input:focus,
.textarea:focus {
  border-color: #FFCC00;
  box-shadow: 0 0 0.1875rem #FFCC00 inset, 0 0 0 0.1875rem rgba(255, 204, 0, 0.2);
  color: #222222;
}
.input {
  padding: 0 0.75em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  height: 2.75rem;
}
@media (min-width: 20rem) {
  .input {
    height: calc(2.75rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .input {
    height: 2.875rem;
  }
}
.textarea {
  line-height: 1.5;
  overflow-x: hidden;
  padding: 0.75em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0.375em 0.75em 0.75em 0;
  position: relative;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #FFCC00;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #FFCC00;
  box-shadow: 0 0 0.1875rem #FFCC00 inset, 0 0 0 0.1875rem rgba(255, 204, 0, 0.2);
  color: #222222;
}
.control input:focus ~ .indicator {
  border-color: #FFCC00;
  box-shadow: 0 0 0.1875rem #FFCC00 inset, 0 0 0 0.1875rem rgba(255, 204, 0, 0.2);
  color: #222222;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.75em;
  position: static;
}
.control .indicator_wrapper .tooltip_wrapper {
  position: static;
}
.control .indicator {
  background: #fff;
  border: 0.0625rem solid #a2a2a2;
  border-radius: 0;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #222222;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.25rem;
}
.select_wrapper {
  display: block;
}
.select {
  align-content: center;
  background: #fff;
  border: 0.0625rem solid #a2a2a2;
  border-radius: 0;
  box-shadow: 0 0 0.1875rem #a2a2a2 inset;
  color: #222222;
  cursor: pointer;
  display: -webkit-box;
  display: flex;
  margin: 0 0 1em;
  padding: 0 0.75em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #222222;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.75em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #FFCC00;
}
.select.error.focus {
  border-color: #FFCC00;
  box-shadow: 0 0 0.1875rem #FFCC00 inset, 0 0 0 0.1875rem rgba(255, 204, 0, 0.2);
  color: #222222;
}
.select.focus {
  border-color: #FFCC00;
  box-shadow: 0 0 0.1875rem #FFCC00 inset, 0 0 0 0.1875rem rgba(255, 204, 0, 0.2);
  color: #222222;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #222222;
  cursor: pointer;
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  min-height: 2.625rem;
}
@media (min-width: 20rem) {
  select {
    min-height: calc(2.625rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  select {
    min-height: 2.75rem;
  }
}
select option {
  min-height: 2.625rem;
}
@media (min-width: 20rem) {
  select option {
    min-height: calc(2.625rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  select option {
    min-height: 2.75rem;
  }
}
select::-ms-expand {
  display: none;
}
select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #222222;
}
select:focus::-ms-value {
  background: none;
  color: #222222;
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(255, 204, 0, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(255, 204, 0, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(255, 204, 0, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(255, 204, 0, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes contact_wiggle {
  0% {
    -webkit-transform: translateX(0.125rem) rotate(10deg);
            transform: translateX(0.125rem) rotate(10deg);
  }
  50% {
    -webkit-transform: translateX(-0.125rem) rotate(-10deg);
            transform: translateX(-0.125rem) rotate(-10deg);
  }
  100% {
    -webkit-transform: translateX(0.125rem) rotate(10deg);
            transform: translateX(0.125rem) rotate(10deg);
  }
}
@keyframes contact_wiggle {
  0% {
    -webkit-transform: translateX(0.125rem) rotate(10deg);
            transform: translateX(0.125rem) rotate(10deg);
  }
  50% {
    -webkit-transform: translateX(-0.125rem) rotate(-10deg);
            transform: translateX(-0.125rem) rotate(-10deg);
  }
  100% {
    -webkit-transform: translateX(0.125rem) rotate(10deg);
            transform: translateX(0.125rem) rotate(10deg);
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@media screen {
  .u_online_secure_login {
    margin-bottom: 1.5em;
  }
  .img_container.u_online_svg_2_small {
    max-width: 18.125rem;
  }
  .img_container.u_online_svg_2_small .svg_resize {
    padding-bottom: 634.48275862%;
  }
  .img_container.u_online_svg_2 {
    margin: 0 0 1.5em;
    max-width: 28.125rem;
  }
  .img_container.u_online_svg_2 .svg_resize {
    padding-bottom: 148.88888889%;
  }
  .u_online_svg_2_small {
    display: block;
    margin: 1.5em auto;
  }
  .u_online_svg_2 {
    display: none;
  }
  .u_online_2_headline {
    fill: #222222;
    font-family: "Kanit", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_2_headline .strong {
    fill: #222222;
    font-weight: 600;
  }
  .u_online_text {
    fill: #222222;
    font-family: "Kanit", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_company_logo {
    fill: #222222;
  }
  .u_online_warning_icon {
    fill: #222222;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_euro_icon,
  .u_online_check_icon {
    fill: #222222;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_icon {
    fill: #FFCC00;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_button .normal {
    display: block;
  }
  .u_online_button .hover {
    display: none;
  }
  .u_online_button:hover .normal {
    display: none;
  }
  .u_online_button:hover .hover {
    display: block;
  }
  .u_online_button:hover .u_online_icon {
    fill: #FFCC00;
  }
  .u_online_button:hover .u_online_euro_icon,
  .u_online_button:hover .u_online_warning_icon,
  .u_online_button:hover .u_online_check_icon {
    fill: #FFCC00;
  }
  .u_online_button:hover .u_online_icon {
    fill: #222222;
  }
  .u_online_lines {
    fill: #FFCC00;
  }
  /*
.u_online_benefits_flow {
    h2 {
        margin-left: auto;
        margin-right: auto;
        max-width: @small_grid_breakpoint;
        text-align: center;
    }

    ul {
        list-style: none !important;
    }

    .table_cell {
        &.text {
            margin: 0 auto;
            max-width: @container_min_width;
        }
    }

    .arrow_large {
        display: none;
    }

    .arrow_small {
        display: block;
        height: (64 / @rem);
        margin: 0 auto;
        width: (30 / @rem);
    }

    .flow_text {
        display: none;
    }

    .u_online_arrow_main_color {
        fill: @main_color;
    }

    .u_online_arrow_main_color_medium {
        fill: tint(@main_color, 50%);
    }

    .header {
        display: table;
        margin: (@spacing / 2) auto;
        max-width: @container_min_width;
        text-align: right;

        .text {
            display: table-cell;
            margin: 0;
            padding: 0 (@spacing / 2) 0 0;
            vertical-align: middle;
        }

        .arrow_small {
            transform: rotate(180deg);
        }
    }

    .footer {
        display: table;
        margin: (@spacing / 2) auto;
        max-width: @container_min_width;

        .text {
            display: table-cell;
            margin: 0;
            padding: 0 0 0 (@spacing / 2);
            vertical-align: middle;
        }
    }

    .description {
        margin: 0 auto;

        ul {
            list-style: none;
            margin: @spacing;
        }

        li {
            margin: round(@spacing / 2, 1) 0;
            padding-left: (34 / @rem);
            position: relative;

            &:before {
                background-color: @main_color;
                //background-image: url("../images/u_online/u_online_check.svg");
                content: "";
                display: block;
                height: (24 / @rem);
                left: 0;
                position: absolute;
                width: (24 / @rem);
            }
        }
    }

    .lock {
        background: @bg_color;
        display: table;
        margin: 0 auto (@spacing / 2);
        padding: @spacing;

        .text {
            display: table-cell;
            padding-left: @spacing;
            vertical-align: middle;
        }

        &:before {
            background: url("../images/u_online/u_online_lock.svg") no-repeat 50% 50%;
            content: "";
            display: table-cell;
            height: (28 / @rem);
            width: (28 / @rem);
        }
    }

    .benefits_button {
        border: 0;
        cursor: pointer;
        display: table;
        margin: 0 auto;
        min-width: (230 / @rem);
        text-decoration: none;

        & > span {
            display: table-cell;
        }

        .text {
            border-bottom-width: (@border_width * 2);
            border-color: @main_color;
            border-left-width: @border_width;
            border-radius: @border_radius 0 0 @border_radius;
            border-style: solid;
            border-top-width: @border_width;
            color: @text_color;
            display: block;
            font-size: 130%;
            font-weight: 500;
            margin-right: (@border_width * 3);
            padding: 0 @spacing;
            text-align: center;
            text-transform: uppercase;
            transition: border @transition_time;
             .scale(line-height, @button_height_min, @button_height_max);
        }

        &:after {
            background-color: @main_color;
            background-image: url("../images/u_online/u_online_button_arrow.svg");
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: 60%;
            border-radius: 0 @border_radius @border_radius 0;
            content: "";
            display: table-cell;
            position: relative;
            transition: background-color @transition_time;
            vertical-align: middle;
            z-index: 1;
             .scale(height, @button_height_min, @button_height_max);
             .scale(width, @button_height_min, @button_height_max);
        }

        &.visible {
            .text {
                border-color: @text_color;
            }

            &:after {
                background-color: @text_color;
                border-radius: @border_radius 0 0 @border_radius;
                transform: rotate(180deg);
            }
        }

        &:hover {
            .text {
                border-color: darken(@main_color, 10%);
            }

            &:after {
                background-color: darken(@main_color, 10%);
            }
        }
    }

    .flow_image {
        border: (@border_width * 4) solid @main_color;
        border-radius: 50%;
        box-sizing: content-box;
        height: (250 / @rem);
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        width: (250 / @rem);

        .text {
            background: fade(@main_color, 85%);
            bottom: 0;
            color: @text_color_light;
            left: 0;
            padding: round(@spacing / 2, 1) 0;
            position: absolute;
            text-align: center;
            width: 100%;
        }

        img {
            border-radius: 50%;
        }
    }
}

@media (min-width: @xlarge_grid_breakpoint) {
    .u_online_benefits_flow {
        .table {
            display: table;
            margin: 0 auto;
            padding: 0;
        }

        .table_cell {
            display: table-cell;
            padding: 0 @spacing;
            vertical-align: middle;

            &.text {
                max-width: (500 / @rem);
            }
        }

        .arrow_large {
            display: block;
        }

        .arrow_small {
            display: none;
        }

        .flow_text {
            display: block;
        }

        .header {
            display: block;
            text-align: center;

            .text {
                display: block;
                margin: 0 auto (@spacing / 2);
                padding: 0;
                text-align: center;
                width: 80%;
            }

        }

        .footer {
            display: block;
            text-align: center;

            .text {
                display: block;
                margin: (@spacing / 2) auto 0;
                padding: 0;
                text-align: center;
                width: 80%;
            }

            .arrow_large {
                transform: rotate(180deg);
            }
        }

        .description {
            max-height: none;
            overflow: none;
            padding: 0 round(@spacing / 2, 1);
        }

        .lock {
            margin-bottom: @spacing;

            &:before {
                height: (56 / @rem);
                width: (56 / @rem);
            }
        }

        .benefits_button {
            display: none;
        }
    }
}*/
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  abbr {
    text-decoration: none;
  }
  [role="tablist"] {
    margin-bottom: 1.5em;
  }
  * + [role="tablist"] {
    margin-top: 1.5em;
  }
  [role="tab"] {
    -webkit-box-align: center;
            align-items: center;
    border-top: 0.0625rem solid #a2a2a2;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 100%;
    margin: 0 0 0.375em;
    min-width: 100%;
    padding: 0.5em 0;
  }
  [role="tab"] .title {
    flex-basis: 100%;
    margin-right: 0.75em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [role="tab"] .icon {
    display: block;
    fill: #FFCC00;
    height: 1.5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 1.5rem;
  }
  [role="tab"][aria-expanded="true"] {
    border-color: #FFCC00;
  }
  [role="tab"][aria-expanded="true"] .icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [role="tab"]:focus {
    border-color: #FFCC00;
    outline: 0;
  }
  [role="tab"]:focus .title {
    color: #FFCC00;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 1.5em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .contact_wrapper p {
    margin-right: 2.25em;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    min-width: 5.3125rem;
  }
  .address_vcard_button {
    margin: 0.75em 0;
  }
  .address_call_button {
    margin: 0.75em 0;
  }
  .service_sidebar {
    margin: 0 auto;
    position: relative;
  }
  .service_sidebar.animated .service_sidebar_wrapper:before {
    bottom: 0;
  }
  .service_sidebar.animated .onlinetools_popup_overview .entry_content .overview_icon,
  .service_sidebar.animated .download_overview .entry_content .overview_icon,
  .service_sidebar.animated .steuernews_issues .entry_content .overview_icon,
  .service_sidebar.animated .address .entry_content .overview_icon,
  .service_sidebar.animated .opening_times .entry_content .overview_icon {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .service_sidebar.animated .onlinetools_popup_overview:before,
  .service_sidebar.animated .download_overview:before,
  .service_sidebar.animated .steuernews_issues:before,
  .service_sidebar.animated .address:before,
  .service_sidebar.animated .opening_times:before {
    -webkit-transform: translateX(-50%) translateY(100%) scale(1);
            transform: translateX(-50%) translateY(100%) scale(1);
  }
  .service_sidebar .icon_down {
    display: inline-block;
    height: 1.375rem;
    margin-bottom: 1.25rem;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 0.875rem;
  }
  .service_sidebar .service_sidebar_wrapper {
    margin-left: 0;
    padding-left: 0;
    position: relative;
    padding-top: 1.5em;
  }
  .service_sidebar .service_sidebar_wrapper:before {
    background: #222222;
    bottom: 100%;
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: 1800ms;
    transition: 1800ms;
    width: 0.0625rem;
  }
  .service_sidebar .onlinetools_popup_overview,
  .service_sidebar .download_overview,
  .service_sidebar .steuernews_issues,
  .service_sidebar .address,
  .service_sidebar .opening_times {
    display: -webkit-box;
    display: flex;
    padding-left: 1.5em;
    position: relative;
    margin-bottom: 1.5em;
  }
  .service_sidebar .onlinetools_popup_overview:before,
  .service_sidebar .download_overview:before,
  .service_sidebar .steuernews_issues:before,
  .service_sidebar .address:before,
  .service_sidebar .opening_times:before {
    background: #222222;
    border-radius: 100%;
    content: "";
    height: 0.78571429rem;
    left: 0.0625rem;
    position: absolute;
    top: 3.375rem;
    -webkit-transform: translateX(-50%) translateY(100%) scale(0);
            transform: translateX(-50%) translateY(100%) scale(0);
    width: 0.78571429rem;
    z-index: 1;
  }
  .service_sidebar .onlinetools_popup_overview:nth-child(1):before,
  .service_sidebar .download_overview:nth-child(1):before,
  .service_sidebar .steuernews_issues:nth-child(1):before,
  .service_sidebar .address:nth-child(1):before,
  .service_sidebar .opening_times:nth-child(1):before {
    -webkit-transition: 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 500ms;
    transition: 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 500ms;
  }
  .service_sidebar .onlinetools_popup_overview:nth-child(1) .entry_content .overview_icon,
  .service_sidebar .download_overview:nth-child(1) .entry_content .overview_icon,
  .service_sidebar .steuernews_issues:nth-child(1) .entry_content .overview_icon,
  .service_sidebar .address:nth-child(1) .entry_content .overview_icon,
  .service_sidebar .opening_times:nth-child(1) .entry_content .overview_icon {
    -webkit-transition: 1200ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 500ms;
    transition: 1200ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 500ms;
  }
  .service_sidebar .onlinetools_popup_overview:nth-child(2):before,
  .service_sidebar .download_overview:nth-child(2):before,
  .service_sidebar .steuernews_issues:nth-child(2):before,
  .service_sidebar .address:nth-child(2):before,
  .service_sidebar .opening_times:nth-child(2):before {
    -webkit-transition: 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 1000ms;
    transition: 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 1000ms;
  }
  .service_sidebar .onlinetools_popup_overview:nth-child(2) .entry_content .overview_icon,
  .service_sidebar .download_overview:nth-child(2) .entry_content .overview_icon,
  .service_sidebar .steuernews_issues:nth-child(2) .entry_content .overview_icon,
  .service_sidebar .address:nth-child(2) .entry_content .overview_icon,
  .service_sidebar .opening_times:nth-child(2) .entry_content .overview_icon {
    -webkit-transition: 1200ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 1000ms;
    transition: 1200ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 1000ms;
  }
  .service_sidebar .onlinetools_popup_overview:nth-child(3):before,
  .service_sidebar .download_overview:nth-child(3):before,
  .service_sidebar .steuernews_issues:nth-child(3):before,
  .service_sidebar .address:nth-child(3):before,
  .service_sidebar .opening_times:nth-child(3):before {
    -webkit-transition: 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 1500ms;
    transition: 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 1500ms;
  }
  .service_sidebar .onlinetools_popup_overview:nth-child(3) .entry_content .overview_icon,
  .service_sidebar .download_overview:nth-child(3) .entry_content .overview_icon,
  .service_sidebar .steuernews_issues:nth-child(3) .entry_content .overview_icon,
  .service_sidebar .address:nth-child(3) .entry_content .overview_icon,
  .service_sidebar .opening_times:nth-child(3) .entry_content .overview_icon {
    -webkit-transition: 1200ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 1500ms;
    transition: 1200ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 1500ms;
  }
  .service_sidebar .onlinetools_popup_overview .entry_content,
  .service_sidebar .download_overview .entry_content,
  .service_sidebar .steuernews_issues .entry_content,
  .service_sidebar .address .entry_content,
  .service_sidebar .opening_times .entry_content {
    position: relative;
  }
  .service_sidebar .onlinetools_popup_overview .entry_content.last .text_wrapper,
  .service_sidebar .download_overview .entry_content.last .text_wrapper,
  .service_sidebar .steuernews_issues .entry_content.last .text_wrapper,
  .service_sidebar .address .entry_content.last .text_wrapper,
  .service_sidebar .opening_times .entry_content.last .text_wrapper {
    position: relative;
  }
  .service_sidebar .onlinetools_popup_overview .entry_content.last .text_wrapper:before,
  .service_sidebar .download_overview .entry_content.last .text_wrapper:before,
  .service_sidebar .steuernews_issues .entry_content.last .text_wrapper:before,
  .service_sidebar .address .entry_content.last .text_wrapper:before,
  .service_sidebar .opening_times .entry_content.last .text_wrapper:before {
    background: #fff;
    content: "";
    height: 90%;
    position: absolute;
    bottom: 0;
    right: 100%;
    width: 6em;
  }
  .service_sidebar .onlinetools_popup_overview .entry_content.last ul,
  .service_sidebar .download_overview .entry_content.last ul,
  .service_sidebar .steuernews_issues .entry_content.last ul,
  .service_sidebar .address .entry_content.last ul,
  .service_sidebar .opening_times .entry_content.last ul {
    position: relative;
    margin-bottom: 0;
  }
  .service_sidebar .onlinetools_popup_overview .entry_content.last ul:before,
  .service_sidebar .download_overview .entry_content.last ul:before,
  .service_sidebar .steuernews_issues .entry_content.last ul:before,
  .service_sidebar .address .entry_content.last ul:before,
  .service_sidebar .opening_times .entry_content.last ul:before {
    background: #fff;
    content: "";
    height: 110%;
    position: absolute;
    bottom: -10%;
    right: 100%;
    width: 6em;
  }
  .service_sidebar .onlinetools_popup_overview .entry_content .overview_title,
  .service_sidebar .download_overview .entry_content .overview_title,
  .service_sidebar .steuernews_issues .entry_content .overview_title,
  .service_sidebar .address .entry_content .overview_title,
  .service_sidebar .opening_times .entry_content .overview_title,
  .service_sidebar .onlinetools_popup_overview .entry_content .overview_description,
  .service_sidebar .download_overview .entry_content .overview_description,
  .service_sidebar .steuernews_issues .entry_content .overview_description,
  .service_sidebar .address .entry_content .overview_description,
  .service_sidebar .opening_times .entry_content .overview_description {
    color: #4E4E4E;
    font-size: 1rem;
  }
  .service_sidebar .onlinetools_popup_overview .entry_content .overview_title,
  .service_sidebar .download_overview .entry_content .overview_title,
  .service_sidebar .steuernews_issues .entry_content .overview_title,
  .service_sidebar .address .entry_content .overview_title,
  .service_sidebar .opening_times .entry_content .overview_title {
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  .service_sidebar .onlinetools_popup_overview .entry_content .overview_description,
  .service_sidebar .download_overview .entry_content .overview_description,
  .service_sidebar .steuernews_issues .entry_content .overview_description,
  .service_sidebar .address .entry_content .overview_description,
  .service_sidebar .opening_times .entry_content .overview_description {
    display: inline;
    margin: 0;
  }
  .service_sidebar .onlinetools_popup_overview .entry_content .overview_icon,
  .service_sidebar .download_overview .entry_content .overview_icon,
  .service_sidebar .steuernews_issues .entry_content .overview_icon,
  .service_sidebar .address .entry_content .overview_icon,
  .service_sidebar .opening_times .entry_content .overview_icon {
    display: block;
    height: 3rem;
    margin-bottom: 1em;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    width: 3rem;
  }
  .service_sidebar .onlinetools_popup_overview ul,
  .service_sidebar .download_overview ul,
  .service_sidebar .steuernews_issues ul,
  .service_sidebar .address ul,
  .service_sidebar .opening_times ul {
    margin-left: 1.5em;
    padding-top: 1.5em;
  }
  .service_sidebar .onlinetools_popup_overview ul .entry,
  .service_sidebar .download_overview ul .entry,
  .service_sidebar .steuernews_issues ul .entry,
  .service_sidebar .address ul .entry,
  .service_sidebar .opening_times ul .entry {
    display: block;
    line-height: 1.8;
    text-decoration: none;
  }
  .service_sidebar .onlinetools_popup_overview ul .entry .title,
  .service_sidebar .download_overview ul .entry .title,
  .service_sidebar .steuernews_issues ul .entry .title,
  .service_sidebar .address ul .entry .title,
  .service_sidebar .opening_times ul .entry .title {
    color: #222222;
    display: block;
    text-decoration: none;
  }
  .service_sidebar .onlinetools_popup_overview ul .entry .title .title_small,
  .service_sidebar .download_overview ul .entry .title .title_small,
  .service_sidebar .steuernews_issues ul .entry .title .title_small,
  .service_sidebar .address ul .entry .title .title_small,
  .service_sidebar .opening_times ul .entry .title .title_small {
    font-size: 90%;
    font-weight: 300;
    text-decoration: underline;
    letter-spacing: calc((0.9375rem + 0.0625 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 0.9375rem;
  }
  .service_sidebar .onlinetools_popup_overview ul .entry .title .title_big,
  .service_sidebar .download_overview ul .entry .title .title_big,
  .service_sidebar .steuernews_issues ul .entry .title .title_big,
  .service_sidebar .address ul .entry .title .title_big,
  .service_sidebar .opening_times ul .entry .title .title_big {
    position: relative;
    text-decoration: none;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    letter-spacing: calc((1rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1rem;
  }
  .service_sidebar .onlinetools_popup_overview ul .entry .title .title_big:before,
  .service_sidebar .download_overview ul .entry .title .title_big:before,
  .service_sidebar .steuernews_issues ul .entry .title .title_big:before,
  .service_sidebar .address ul .entry .title .title_big:before,
  .service_sidebar .opening_times ul .entry .title .title_big:before {
    background: #FFCC00;
    bottom: 0;
    content: "";
    height: 0.125rem;
    left: 0;
    position: absolute;
    right: 100%;
    -webkit-transition: right 300ms ease-out;
    transition: right 300ms ease-out;
    z-index: -1;
  }
  .service_sidebar .onlinetools_popup_overview ul .entry:hover .title .title_big:before,
  .service_sidebar .download_overview ul .entry:hover .title .title_big:before,
  .service_sidebar .steuernews_issues ul .entry:hover .title .title_big:before,
  .service_sidebar .address ul .entry:hover .title .title_big:before,
  .service_sidebar .opening_times ul .entry:hover .title .title_big:before,
  .service_sidebar .onlinetools_popup_overview ul .entry:focus .title .title_big:before,
  .service_sidebar .download_overview ul .entry:focus .title .title_big:before,
  .service_sidebar .steuernews_issues ul .entry:focus .title .title_big:before,
  .service_sidebar .address ul .entry:focus .title .title_big:before,
  .service_sidebar .opening_times ul .entry:focus .title .title_big:before {
    right: 0;
  }
  .service_sidebar .onlinetools_popup_overview ul .entry:hover .title .title_small,
  .service_sidebar .download_overview ul .entry:hover .title .title_small,
  .service_sidebar .steuernews_issues ul .entry:hover .title .title_small,
  .service_sidebar .address ul .entry:hover .title .title_small,
  .service_sidebar .opening_times ul .entry:hover .title .title_small,
  .service_sidebar .onlinetools_popup_overview ul .entry:focus .title .title_small,
  .service_sidebar .download_overview ul .entry:focus .title .title_small,
  .service_sidebar .steuernews_issues ul .entry:focus .title .title_small,
  .service_sidebar .address ul .entry:focus .title .title_small,
  .service_sidebar .opening_times ul .entry:focus .title .title_small {
    text-decoration: none;
  }
  .atikon_logo {
    height: 100%;
  }
  .author_hor {
    display: inline-block;
    fill: #222222;
    height: 0.5625rem;
    line-height: 0.5625rem;
    width: 4.6875rem;
  }
  .author_ver {
    display: inline-block;
    fill: #222222;
    height: 4.6875rem;
    line-height: 4.6875rem;
    width: 0.5625rem;
  }
  .breadcrumbs {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.75em;
  }
  .breadcrumbs p {
    margin: 0 0.375em 0 0;
  }
  .breadcrumbs ol {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .breadcrumbs .separator {
    margin: 0 0.375em;
  }
  .company_logo {
    display: block;
    width: 13.4375rem;
  }
  .cookie_banner {
    -webkit-box-align: center;
            align-items: center;
    background: #FFCC00;
    border-top: 0.0625rem solid #FFCC00;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 9000;
  }
  .cookie_banner p {
    flex-basis: 100%;
    margin: 0;
    padding: 1em 0.75em 1em 1.5em;
  }
  .cookie_banner .close {
    cursor: pointer;
    font-size: 130%;
    font-weight: 600;
    margin-right: 0.75em;
    padding: 0 0.75em;
  }
  .cookie_banner .close > span {
    min-height: 2.625rem;
  }
  .cookie_banner .close .text {
    padding: 0 0.3em;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
  }
  .download_overview {
    margin-bottom: 0.75em;
  }
  .download_overview .touch_link {
    display: -webkit-box;
    display: flex;
  }
  .download_overview .touch_link .title_wrapper {
    margin-bottom: 0.375em;
    padding-top: 0;
  }
  .footer_wrapper {
    margin-bottom: 3em;
  }
  body:not(.startpage) .footer_wrapper {
    margin-top: 3em;
  }
  .footer_address .footer_contact_wrapper {
    display: -webkit-box;
    display: flex;
  }
  .footer_address .footer_contact_wrapper .contact {
    -webkit-box-align: center;
            align-items: center;
    margin-right: 0.75em;
  }
  .footer_address .icon {
    display: inline-block;
    margin-right: 0.5em;
    margin-top: -0.1875rem;
  }
  .footer_address .icon_marker {
    height: 1rem;
    width: 0.8125rem;
  }
  .footer_address .icon_call {
    height: 0.9375rem;
    width: 0.9375rem;
  }
  .footer_address .icon_mail {
    height: 0.9375rem;
    width: 1.375rem;
  }
  .footer_address .icon_clock {
    height: 1.0625rem;
    width: 1.0625rem;
  }
  .related_links {
    margin-top: 1.5em;
  }
  .related_links ul {
    display: -webkit-box;
    display: flex;
  }
  .related_links ul li {
    margin-bottom: 0.75em;
    margin-right: 0.75em;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 20rem;
  }
  .bypass_blocks a {
    background: #fff;
    color: #222222;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.375em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #222222;
    height: auto;
    left: 0.375em;
    top: 0.375em;
    width: auto;
  }
  .button {
    border-radius: 0;
    padding: 0 1.95em;
    background: #FFCC00;
    border: 0.125rem solid #FFCC00;
    color: #222222;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .button .icon {
    fill: #222222;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button .text {
    font-weight: 600;
    padding: 0.375em 0;
    letter-spacing: calc((0.875rem + 0.25 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 0.875rem;
  }
  .button .icon + .text {
    padding-left: 0.5em;
  }
  .button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(255, 204, 0, 0.2);
    outline: 0;
    background: #ffd633;
    border-color: #ffd633;
    color: #fff;
  }
  .button:focus .icon {
    fill: #fff;
  }
  .button:hover {
    background: #fff;
    border-color: #FFCC00;
    color: #222222;
  }
  .button:hover .icon {
    fill: #222222;
  }
  .button:active,
  .button.active {
    box-shadow: 0 0 0.1875rem #FFCC00 inset;
    background: #FFCC00;
    border: 0.125rem solid #FFCC00;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button:active > span,
  .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .button:active .icon,
  .button.active .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button:active .text,
  .button.active .text {
    font-weight: 600;
    padding: 0.375em 0;
    letter-spacing: calc((0.875rem + 0.25 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 0.875rem;
  }
  .button:active .icon + .text,
  .button.active .icon + .text {
    padding-left: 0.5em;
  }
  .button_overview {
    display: -webkit-box;
    display: flex;
  }
  .button_overview .button {
    margin-right: 0.75em;
  }
  .google_maps {
    margin: 0 0 0.75em;
  }
  .google_maps .map {
    background: #bbbbbb;
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map .gm-style > button {
    width: auto;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: flex;
    margin: 0 0 0.75em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio {
    z-index: 5;
    background: #FFCC00;
    border-color: #FFCC00;
    color: #fff;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.1875rem rgba(255, 204, 0, 0.2);
    z-index: 15;
    background: #ffd633;
    border-color: #ffd633;
    color: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode_entry {
    flex-basis: 100%;
    height: 2.75rem;
  }
  .google_maps .travel_mode_radio {
    cursor: pointer;
    position: relative;
    width: 100%;
    background: #fff;
    border: 0.125rem solid #a2a2a2;
    color: #222222;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border: 0.0625rem solid #a2a2a2;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #222222;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .google_maps .travel_mode_radio .text {
    font-weight: 600;
    padding: 0.375em 0;
    letter-spacing: calc((0.875rem + 0.25 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 0.875rem;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.5em;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #222222;
    min-width: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 0 0 0 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 0 0 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 0;
    color: #FFCC00;
    font-size: 85%;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .google_maps .direction {
    margin: 0.75em 0;
  }
  .google_maps .button_wrapper {
    margin-top: auto;
  }
  .google_maps .panel_wrapper {
    padding-top: 0.75em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.0625rem solid #a2a2a2;
    display: -webkit-box;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.0625rem solid #a2a2a2;
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 0.75em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 0.75em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 0.75em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .google_maps .infowindow {
    max-width: 13.75rem;
  }
  .header_top {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    padding-bottom: 0.75em;
    padding-top: 1.5em;
  }
  .header_top .button {
    display: none;
  }
  .header_top .mobile_menu_bar {
    flex-wrap: wrap;
  }
  .header_bottom {
    position: relative;
  }
  .header_bottom .container {
    bottom: 0;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 100%;
    margin: 0 auto;
    max-width: 100rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  @supports (padding-left: env(safe-area-inset-left)) {
    html.iphone .header_bottom .container {
      padding-left: max(0.9375rem, env(safe-area-inset-left));
      padding-right: max(0.9375rem, env(safe-area-inset-right));
    }
  }
  .header_bottom .image_content_overlay {
    padding-bottom: 0.75em;
  }
  .header_bottom .slogan_box {
    -webkit-box-pack: end;
            justify-content: flex-end;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    min-width: 15.625rem;
  }
  .header_bottom .slogan_box h1 {
    margin-bottom: 0;
    text-shadow: 0 0 0.5rem #fff;
  }
  .startpage .header_bottom .slogan_box h1 {
    margin-bottom: 0.5em;
  }
  .header_bottom .slogan_box .slogan_small,
  .header_bottom .slogan_box .slogan_big {
    display: block;
  }
  .header_bottom .slogan_box .slogan_small {
    letter-spacing: calc((1.0625rem + 0.875 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1.0625rem;
    margin-top: 0.33333333em;
  }
  .header_bottom .slogan_box .slogan_big {
    letter-spacing: calc((1.25rem + 1.375 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1.25rem;
    line-height: 1.1;
  }
  .header_bottom .slogan_box .button {
    display: none;
  }
  .header_bottom .digi_button,
  .header_bottom .career_button {
    display: none;
    margin-bottom: 0;
    margin-top: 0.75em;
    -webkit-box-pack: start;
            justify-content: flex-start;
    margin-left: 0;
  }
  .header_bottom .career_button {
    margin-bottom: 0;
  }
  .header_bottom .career_button .button {
    background-color: #154f8f;
    border-color: #154f8f;
  }
  .header_bottom .career_button .button .text {
    color: #fff;
  }
  .header_bottom .career_button .button:hover,
  .header_bottom .career_button .button:active,
  .header_bottom .career_button .button:focus {
    background-color: #fff;
  }
  .header_bottom .career_button .button:hover .text,
  .header_bottom .career_button .button:active .text,
  .header_bottom .career_button .button:focus .text {
    color: #222222;
  }
  .header_bottom .contact_box {
    display: none;
  }
  .header_bottom .contact_box .home_btn .icon {
    margin-bottom: 0.1875rem;
    margin-right: 0;
  }
  .header_bottom .contact_box a {
    -webkit-box-align: center;
            align-items: center;
    color: #222222;
    display: -webkit-box;
    display: flex;
    font-size: 0.875rem;
    text-decoration: none;
  }
  .header_bottom .contact_box a .text {
    display: inline-block;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
  .header_bottom .contact_box a .text:before {
    background: #FFCC00;
    bottom: 0;
    content: "";
    height: 0.125rem;
    left: 0;
    position: absolute;
    right: 100%;
    -webkit-transition: right 300ms ease-out;
    transition: right 300ms ease-out;
    z-index: -1;
  }
  .header_bottom .contact_box a:hover .text:before,
  .header_bottom .contact_box a:focus .text:before {
    right: 0;
  }
  .header_bottom .contact_box a:hover .icon {
    -webkit-animation-duration: 300ms;
            animation-duration: 300ms;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: contact_wiggle;
            animation-name: contact_wiggle;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  .header_bottom .contact_box .icon {
    display: inline-block;
    height: 1.125rem;
    margin-right: 0.75em;
    width: 1.125rem;
  }
  .header_picture img {
    display: block;
    margin: 0 auto;
  }
  .container {
    margin: 0 auto;
    max-width: 100rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .form_disclaimer {
    background: #fff;
    border: 0.125rem solid #FFCC00;
    border-radius: 0;
    color: #222222;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .file {
    display: -webkit-box;
    display: flex;
    flex-basis: 65%;
    margin: 0 0 1em;
    position: relative;
    height: 2.75rem;
  }
  .file.error .file_name {
    border-color: #FFCC00;
  }
  .file .file_name {
    background: #fff;
    border: 0.0625rem solid #a2a2a2;
    border-radius: 0 0 0 0;
    box-shadow: 0 0 0.1875rem #a2a2a2 inset;
    color: #222222;
    -webkit-box-flex: 0;
            flex: 0 1 100%;
    overflow: hidden;
    padding: 0 0.75em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 8.125rem;
    line-height: 2.625rem;
    height: 2.75rem;
  }
  .file .file_button {
    border-radius: 0 0 0 0;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    padding: 0 1.5em;
    z-index: 10;
    background: #FFCC00;
    border: 0.125rem solid #FFCC00;
    color: #222222;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .file .file_button .icon {
    fill: #222222;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file .file_button .text {
    font-weight: 600;
    padding: 0.375em 0;
    letter-spacing: calc((0.875rem + 0.25 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 0.875rem;
  }
  .file .file_button .icon + .text {
    padding-left: 0.5em;
  }
  .file .file_button.focus {
    box-shadow: 0 0 0 0.1875rem rgba(255, 204, 0, 0.2);
    background: #ffd633;
    border-color: #ffd633;
    color: #fff;
  }
  .file .file_button.focus .icon {
    fill: #fff;
  }
  .file [type="file"] {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;
    height: 2.75rem;
  }
  .file [type="file"]:hover ~ .file_button {
    background: #fff;
    border-color: #FFCC00;
    color: #222222;
  }
  .file [type="file"]:hover ~ .file_button .icon {
    fill: #222222;
  }
  .file [type="file"]:active ~ .file_button {
    box-shadow: 0 0 0.1875rem #FFCC00 inset;
    background: #FFCC00;
    border: 0.125rem solid #FFCC00;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file [type="file"]:active ~ .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .file [type="file"]:active ~ .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file [type="file"]:active ~ .file_button .text {
    font-weight: 600;
    padding: 0.375em 0;
    letter-spacing: calc((0.875rem + 0.25 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 0.875rem;
  }
  .file [type="file"]:active ~ .file_button .icon + .text {
    padding-left: 0.5em;
  }
  button {
    cursor: pointer;
    margin: 0.75em 0;
    outline: 0;
    overflow: hidden;
    padding: 0 1.5em;
    width: 100%;
    height: 2.75rem;
  }
  button::-moz-focus-inner {
    border: 0;
  }
  .infolist_overview {
    padding-top: 1.5em;
  }
  .infolist_overview .entry {
    -webkit-box-align: center;
            align-items: center;
    border: 0.0625rem solid #222222;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    margin-bottom: 2.25em;
    text-align: center;
    text-decoration: none;
    padding-bottom: 1.5em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-top: 1.5em;
  }
  .infolist_overview .entry:hover .title:before {
    right: 0;
  }
  .infolist_overview .entry:hover .icon {
    -webkit-transform: translateZ(0) scale(1.15);
            transform: translateZ(0) scale(1.15);
  }
  .infolist_overview .entry .title {
    letter-spacing: calc((1.125rem + 0.625 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1.125rem;
    color: #222222;
    display: inline;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-decoration: none;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
  .infolist_overview .entry .title:before {
    background: #FFCC00;
    bottom: 0;
    content: "";
    height: 0.125rem;
    left: 0;
    position: absolute;
    right: 100%;
    -webkit-transition: right 300ms ease-out;
    transition: right 300ms ease-out;
    z-index: -1;
  }
  .infolist_overview .entry p {
    margin-top: 1.25em;
    width: 100%;
  }
  .infolist_overview .entry .icon {
    display: block;
    height: 4.8125rem;
    margin: 0 auto 1.25em;
    -webkit-transition: 300ms;
    transition: 300ms;
    width: 4.8125rem;
  }
  .back_link_infolists {
    display: inline-block;
    margin-bottom: 1.5em;
  }
  .amount {
    display: block;
    text-align: right;
    white-space: nowrap;
  }
  .steuerlexikon .amount {
    display: table-cell;
  }
  .multi_columns li {
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }
  .infolist_top_link {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0.75em 0;
  }
  .infolist_top_link a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    text-align: center;
  }
  .infolist_top_link .icon {
    display: block;
    fill: #FFCC00;
    height: 3rem;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    width: 3rem;
  }
  .disclaimer {
    background: #fff;
    border: 0.125rem solid #FFCC00;
    border-radius: 0;
    color: #222222;
    font-size: 85%;
    margin-bottom: 0.75em;
    margin-top: 1.5em;
    padding: 1em 1.5em;
  }
  .infolist_download {
    -webkit-box-align: baseline;
            align-items: baseline;
    background: #fff;
    border: 0.125rem solid #FFCC00;
    border-radius: 0;
    color: #222222;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .infolist_download .infolist_title {
    font-weight: 600;
  }
  .infolist_download p {
    margin-right: 1.5em;
  }
  .checklist_entry {
    border-top: 0.0625rem solid #a2a2a2;
    margin-bottom: 0.75em;
    padding-top: 0.75em;
  }
  .checklist_entry:first-child {
    border-top: 0;
    padding-top: 0;
  }
  @supports (padding-left: env(safe-area-inset-left)) {
    html.iphone .container {
      padding-left: max(0.9375rem, env(safe-area-inset-left));
      padding-right: max(0.9375rem, env(safe-area-inset-right));
    }
  }
  .legal_notice_font_overview p {
    margin-bottom: 0;
  }
  .legal_notice_font_overview li {
    margin-bottom: 0.75em;
  }
  body {
    overflow-x: hidden;
    margin-left: 0.75em;
  }
  .startpage h2 {
    margin-bottom: 0;
    letter-spacing: calc((1.3125rem + 1.0625 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1.3125rem;
  }
  main {
    padding-top: 1.5em;
  }
  .startpage main {
    padding-top: 0;
  }
  .startpage_introduction {
    padding-bottom: 1.5em;
    padding-top: 1.5em;
  }
  .startpage_introduction .title_small {
    display: inline-block;
    font-weight: 600;
    margin-bottom: 1.5em;
  }
  .startpage_introduction .description,
  .startpage_introduction .title_small {
    letter-spacing: calc((0.9375rem + 0.3125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 0.9375rem;
  }
  .startpage_introduction .description {
    line-height: 1.7;
    margin-bottom: 1.5em;
  }
  .highlight_box {
    border: 0.0625rem solid #FFCC00;
    padding: 1.5em;
  }
  .toggle_offcanvas_menu {
    display: inline-block;
    position: relative;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 1.0625rem;
    font-weight: 600;
    height: 2.75rem;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .icon {
    height: 1.875rem;
    margin-right: 0.75em;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 350ms 150ms;
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
    width: 2.25rem;
  }
  .toggle_offcanvas_menu label .line {
    background: #222222;
    display: block;
    height: 0.1875rem;
    position: absolute;
    width: 100%;
  }
  .toggle_offcanvas_menu label .line_1 {
    opacity: 1;
    top: 0.1875rem;
    -webkit-transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, transform 300ms;
    transition: top 300ms 300ms, transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_2 {
    opacity: 1;
    top: 0.8125rem;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_3 {
    bottom: 0.1875rem;
    -webkit-transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms, -webkit-transform 300ms;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .header_offcanvas_menu_wrapper {
    height: 100%;
    position: absolute;
    top: 0;
    margin-left: -0.75em;
  }
  .header_offcanvas_menu_wrapper .top_wrapper {
    margin-bottom: 1.875rem;
    margin-left: 1.5em;
    margin-right: 1.5em;
  }
  .header_offcanvas_menu_wrapper .close_button {
    display: none;
  }
  .offcanvas_mobile_close_button {
    -webkit-box-align: center;
            align-items: center;
    background: #FFCC00;
    display: -webkit-box;
    display: flex;
    position: fixed;
    right: -2.955em;
    top: 1.5em;
  }
  .offcanvas_mobile_close_button:hover,
  .offcanvas_mobile_close_button:focus {
    cursor: pointer;
  }
  .offcanvas_mobile_close_button .icon {
    display: inline-block;
    fill: #222222;
    height: 2.75rem;
    width: 2.75rem;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_1 {
    top: 0.8125rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_3 {
    bottom: 0.8125rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .open_offcanvas_menu,
  .offcanvas_menu_state:checked ~ div .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ div .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ header .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .close_offcanvas_menu,
  .offcanvas_menu_state:checked ~ div .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ div .close_offcanvas_menu {
    display: block;
  }
  .offcanvas_menu {
    background-color: #154f8f;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
    display: block;
    min-height: 100%;
    opacity: 0;
    padding: 1.5em 0;
    position: absolute;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms, -webkit-transform 240ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 15.625rem;
    z-index: 5000;
    left: 0;
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  .offcanvas_menu .offcanvas_menu_content > ul {
    width: 15.625rem;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    letter-spacing: calc((1.125rem + 0.375 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1.125rem;
    color: #fff;
    display: inline-block;
    font-weight: 600;
    line-height: 1.8;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:before {
    background: #FFCC00;
    bottom: 0;
    content: "";
    height: 0.1875rem;
    left: 0;
    position: absolute;
    right: 100%;
    -webkit-transition: right 300ms ease-out;
    transition: right 300ms ease-out;
    z-index: -1;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:hover,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:focus,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active {
    color: #fff;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:hover:before,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:focus:before,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active:before {
    right: 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul li.expanded > ul {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    color: #fff;
    display: block;
    margin-right: 1.5em;
    padding: 0.375em 0 0.375em 0;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a:hover,
  .offcanvas_menu .offcanvas_menu_content > ul ul a:focus {
    text-decoration: underline;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    color: #fff;
    text-decoration: underline;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul ul {
    border-bottom: 0.0625rem solid #a2a2a2;
    border-top: 0.0625rem solid #a2a2a2;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: block !important;
    height: 0;
    margin-bottom: 0;
    overflow: hidden;
    -webkit-transition: height 600ms;
    transition: height 600ms;
  }
  .open_street_map {
    position: relative;
    z-index: 100;
  }
  .open_street_map .map {
    height: 21.875rem;
    background-color: #bbbbbb;
    cursor: move;
    position: relative;
    width: 100%;
  }
  .open_street_map .map_overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(187, 187, 187, 0.85);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 1;
  }
  .open_street_map .map_overlay.show {
    opacity: 1;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .open_street_map .map_overlay.show p {
    font-size: 120%;
    margin: 1.5em 3em;
    text-align: center;
  }
  .open_street_map .required_description {
    margin: 1.5em 0 0 0;
  }
  .open_street_map .form_disclaimer {
    background: #fff !important;
    margin: 1.5em 0 0 0;
  }
  .open_street_map .plan_route {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    margin: 1.5em 0;
    position: relative;
  }
  .open_street_map .plan_route .saddr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
  }
  .open_street_map .plan_route .select_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin-top: 0.75em;
  }
  .open_street_map .plan_route .form_disclaimer {
    margin-top: 1.5em;
  }
  .open_street_map .ol-viewport {
    touch-action: pan-y !important;
  }
  .open_street_map .ol-overlaycontainer-stopevent button {
    -webkit-box-align: center;
            align-items: center;
    background: #FFCC00;
    border: 0.0625rem solid #FFCC00;
    border-radius: 0;
    color: #222222;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 600;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:focus {
    background: #ffd633;
    border: 0.0625rem solid #ffd633;
    box-shadow: 0 0 0 0.1875rem rgba(255, 204, 0, 0.2);
    color: #fff;
    outline: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:hover {
    background: #fff;
    border: 0.0625rem solid #FFCC00;
    color: #222222;
  }
  .open_street_map .ol-overlaycontainer-stopevent button.disabled {
    cursor: default;
    opacity: 0.6;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    position: absolute;
    right: 0.3125rem;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in {
    line-height: 1;
    top: 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out {
    line-height: 1;
    top: 3.375rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-rotate-reset {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    cursor: default;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    right: 0;
    text-align: right;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    display: -webkit-box;
    display: flex;
    height: 2.875rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center right;
            transform-origin: center right;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li {
    -webkit-box-align: center;
            align-items: center;
    background-image: none !important;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    margin: 0 0.75em;
    padding: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution button {
    display: none;
  }
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-out {
    display: none;
  }
  .open_street_map .form_disclaimer {
    background: #bbbbbb;
    border-radius: 0;
    color: #222222;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .opening_times ul {
    margin-bottom: 0;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: #a2a2a2;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(34, 34, 34, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.5em;
  }
  .popup_close {
    -webkit-box-align: center;
            align-items: center;
    background: #FFCC00;
    border-radius: 0 0 0 0;
    display: -webkit-box;
    display: flex;
    float: right;
    -webkit-box-pack: center;
            justify-content: center;
    margin-right: 0.0625rem;
    padding: 0.42857143em;
    text-align: center;
    height: 2.75rem;
    width: 2.75rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 100rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms, -webkit-transform 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 1.5625rem rgba(0, 0, 0, 0.2);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.75rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .toggle_search_box {
    display: inline-block;
    position: relative;
    margin-left: 1em;
  }
  .toggle_search_box label {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
  .toggle_search_box label:hover ~ a {
    fill: #FFCC00;
  }
  .toggle_search_box a {
    fill: #222222;
  }
  .toggle_search_box a .icon {
    display: block;
    height: 1.5625rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5625rem;
  }
  .toggle_search_box .open_search_box {
    display: block;
  }
  .toggle_search_box .close_search_box {
    display: none;
  }
  .search_box {
    -webkit-backdrop-filter: blur(0.125rem);
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    padding: 0.75em;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 9999;
  }
  .search_box .close_search_box_wrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .search_box .close_search_box_wrapper label {
    background: rgba(34, 34, 34, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
  }
  .search_box .close_search_box_wrapper .close_search_box {
    -webkit-box-align: center;
            align-items: center;
    background: #FFCC00;
    border: 0.0625rem solid #FFCC00;
    border-radius: 0;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.2);
    fill: #fff;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 0.25rem;
    position: absolute;
    right: 1.5em;
    top: 1.5em;
    width: 2.75rem;
  }
  .search_box .close_search_box_wrapper .close_search_box .icon {
    display: block;
    height: 2.125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
  }
  .search_box .close_search_box_wrapper .close_search_box:focus {
    background: #ffd633;
    border-color: #ffd633;
    outline: 0;
  }
  .search_box .close_search_box_wrapper .close_search_box:hover {
    background: #fff;
    border-color: #FFCC00;
    color: #222222;
  }
  .search_box .close_search_box_wrapper .close_search_box:hover .icon {
    fill: #222222;
  }
  .search_box form[role="search"] {
    background: #fff;
    border: 0.625rem solid #fff;
    border-radius: 0;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.2);
    display: -webkit-box;
    display: flex;
    opacity: 0;
    position: relative;
    -webkit-transform: translateY(-6.25rem);
            transform: translateY(-6.25rem);
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: hidden;
    width: 37.5rem;
    z-index: 1000;
  }
  .search_box form[role="search"] button {
    width: 2.75rem;
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
  }
  .search_box input[type="search"] {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    font-size: 115%;
    -webkit-user-select: text;
       -moz-user-select: text;
        -ms-user-select: text;
            user-select: text;
  }
  .search_box_state {
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
  }
  .search_box_state:checked ~ .search_box,
  .search_box_state:target ~ .search_box {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box .close_search_box_wrapper label,
  .search_box_state:target ~ .search_box .close_search_box_wrapper label {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box form[role="search"],
  .search_box_state:target ~ .search_box form[role="search"] {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    visibility: visible;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box {
    display: none;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box {
    display: -webkit-box;
    display: flex;
  }
  form[role="search"] {
    display: -webkit-box;
    display: flex;
  }
  form[role="search"] button {
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    background: #FFCC00;
    border: 0.125rem solid #FFCC00;
    color: #222222;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  form[role="search"] button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  form[role="search"] button .icon {
    fill: #222222;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  form[role="search"] button .text {
    font-weight: 600;
    padding: 0.375em 0;
    letter-spacing: calc((0.875rem + 0.25 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 0.875rem;
  }
  form[role="search"] button .icon + .text {
    padding-left: 0.5em;
  }
  form[role="search"] button[disabled] {
    background-color: #FFCC00;
    border-color: #FFCC00;
    color: #222222;
  }
  form[role="search"] button[disabled] .icon {
    fill: #222222;
  }
  form[role="search"] button > span {
    width: 100%;
  }
  form[role="search"] button:focus {
    position: relative;
    z-index: 10;
    background: #ffd633;
    border-color: #ffd633;
    color: #fff;
  }
  form[role="search"] button:focus .icon {
    fill: #fff;
  }
  form[role="search"] button .icon {
    height: 2.625rem;
  }
  input[type="search"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #fff;
    border: 0.125rem solid #222222;
    border-radius: 0 0 0 0;
    box-shadow: 0 0 0 #a2a2a2 inset;
    color: #222222;
    flex-basis: 100%;
    margin: 0;
    min-width: 12.5rem;
    padding: 0 0.75em;
    -webkit-transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, z-index 300ms;
    transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, z-index 300ms;
    height: 2.75rem;
  }
  input[type="search"]:focus {
    background: #fff;
    border-color: #FFCC00;
    color: #222222;
    outline: 0;
    position: relative;
    z-index: 10;
  }
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .search dl {
    margin: 0 0 0.75em;
    padding-top: 0.375em;
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .search dd {
    margin: 0;
  }
  .steuernews_preview {
    position: relative;
    z-index: 10;
    margin-bottom: 0.75em;
    margin-top: 1.5em;
    padding-bottom: 1.5em;
    padding-top: 1.5em;
  }
  body:not(.news) .steuernews_preview {
    background-image: url("../images/steuernews_hintergrund.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 1.5em;
  }
  .steuernews_preview .overview_title {
    padding: 0;
  }
  .steuernews_preview .title_small {
    display: block;
    font-weight: 600;
    margin-bottom: 1.5em;
  }
  .steuernews_preview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 1.5em;
  }
  .steuernews_preview .entry:hover .image,
  .steuernews_preview .entry:focus .image {
    opacity: 1;
  }
  .steuernews_preview .entry:hover .image img,
  .steuernews_preview .entry:focus .image img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .steuernews_preview .entry:hover .fake_link:before,
  .steuernews_preview .entry:focus .fake_link:before {
    right: 0;
  }
  .steuernews_preview .image {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    line-height: 0;
    margin: 0;
    opacity: 0.66;
    overflow: hidden;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .steuernews_preview .image img {
    margin-right: 1.5em;
    -webkit-transition: 600ms;
    transition: 600ms;
  }
  .steuernews_preview .title {
    color: #222222 !important;
    margin-top: 1.5em;
    text-decoration: none !important;
    word-wrap: break-word;
  }
  .steuernews_preview .fake_link {
    display: inline-block;
    font-weight: 600;
    overflow: hidden;
    position: relative;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
  .steuernews_preview .fake_link:before {
    background: #FFCC00;
    bottom: 0;
    content: "";
    height: 0.125rem;
    left: 0;
    position: absolute;
    right: 100%;
    -webkit-transition: right 300ms ease-out;
    transition: right 300ms ease-out;
    z-index: -1;
  }
  .steuernews_preview .all_article_button {
    margin-top: 1.5em;
  }
  .news_entity .hide_image_author,
  .news_entity .hide_creation_date {
    display: none;
  }
  .news_image {
    float: right;
    margin-left: 1.5em;
  }
  .news_image + * {
    padding-top: 0;
  }
  .steuernews_issues ul {
    margin-bottom: 0;
  }
  .steuernews_disclaimer {
    clear: both;
  }
  .steuernews_sidebar {
    margin: 1.5em 0 0.75em;
  }
  .steuernews_current_issue {
    margin-bottom: 0.75em;
  }
  .steuernews_article_slider {
    display: -webkit-box;
    display: flex;
    overflow: hidden;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
  }
  .steuernews_article_slider .slider {
    display: -webkit-box;
    display: flex;
    flex-wrap: nowrap;
    left: 0;
    position: absolute;
    -webkit-transition: -webkit-transform 600ms;
    transition: -webkit-transform 600ms;
    transition: transform 600ms;
    transition: transform 600ms, -webkit-transform 600ms;
  }
  .steuernews_article_slider .slide {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-shrink: 0;
    position: relative;
  }
  .steuernews_article_slider .slide .image {
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
    max-width: 24.1875rem;
  }
  .steuernews_article_slider .slide .description {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    padding: 0.75em 0;
  }
  .steuernews_article_slider .slide .description .title {
    margin: 0;
    word-wrap: break-word;
  }
  .steuernews_article_slider .slide .description .sub_title {
    display: block;
    margin-bottom: 0.75em;
  }
  .steuernews_article_slider .control_slides {
    -webkit-box-align: center;
            align-items: center;
    background: #bbbbbb;
    display: -webkit-box;
    display: flex;
    fill: #222222;
    height: 3.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin-top: -1.875rem;
    opacity: 0;
    padding: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 30%;
    -webkit-transition: background 300ms, opacity 300ms, visibility 300ms;
    transition: background 300ms, opacity 300ms, visibility 300ms;
    visibility: none;
    width: 3.75rem;
    z-index: 15;
  }
  .steuernews_article_slider .control_slides.show {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }
  .steuernews_article_slider .control_slides:focus,
  .steuernews_article_slider .control_slides:hover {
    fill: #FFCC00;
    outline: 0;
  }
  .steuernews_article_slider .control_slides .icon {
    height: 1.75rem;
    width: 1.75rem;
  }
  .steuernews_article_slider .prev_slide {
    left: -0.0625rem;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .steuernews_article_slider .next_slide {
    right: -0.0625rem;
  }
  .steuernews_contact_person {
    background: #bbbbbb;
    border-radius: 0;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .steuernews_contact_person .team_entry {
    display: -webkit-box;
    display: flex;
  }
  .steuernews_contact_person .team_entry figure {
    display: block;
  }
  .steuernews_contact_person .contact_person_entry {
    height: auto;
    margin-top: 1.5em;
    position: relative;
  }
  .steuernews_contact_person .more_team_contacts {
    background: #fff;
    border-radius: 0;
    color: #FFCC00;
    display: block;
    line-height: 1.5;
    margin: 1em 0;
    padding: 0.75em;
    text-align: center;
  }
  .steuernews_newsletter_subscribe {
    background: #bbbbbb;
    border-radius: 0;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .steuernews_newsletter_subscribe .disclaimer {
    background-color: #fff;
  }
  .steuernews_video_overview {
    margin-bottom: 1.5em;
  }
  .steuernews_video_overview .entry {
    display: inline-block;
  }
  .steuernews_video_overview .image {
    margin-bottom: 0.75em;
    position: relative;
  }
  .steuernews_video_overview .video_play {
    position: relative;
  }
  .steuernews_video_overview .video_play .icon {
    fill: #FFCC00;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .team_overview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    margin-bottom: 0.75em;
  }
  .team_overview .title {
    margin: 0;
    padding: 0;
  }
  .team_overview .photo {
    display: -webkit-box;
    display: flex;
    margin: 0 1.5em 0.75em 0;
    max-width: 8.75rem;
  }
  .team_overview .photo img {
    display: block;
  }
  .team_overview .info {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    margin-bottom: 0.75em;
  }
  .team_entry .photo {
    margin-bottom: 0.75em;
    max-width: 8.75rem;
  }
  .team_entry .photo img {
    display: block;
  }
  .team_entry .info {
    margin-bottom: 0.75em;
  }
  .teaser_overview {
    padding-bottom: 1.5em;
    padding-top: 1.5em;
  }
  .overview {
    padding-top: 1.07142857em;
  }
  .teaser_overview .teaser,
  .overview .teaser,
  .teaser_overview .entry,
  .overview .entry {
    -webkit-box-align: center;
            align-items: center;
    border: 0.0625rem solid #222222;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    margin-bottom: 2.25em;
    text-align: center;
    padding-bottom: 1.5em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-top: 1.5em;
  }
  .teaser_overview .teaser a,
  .overview .teaser a,
  .teaser_overview .entry a,
  .overview .entry a {
    text-decoration: none;
    width: 100%;
  }
  .teaser_overview .teaser a:hover .title:before,
  .overview .teaser a:hover .title:before,
  .teaser_overview .entry a:hover .title:before,
  .overview .entry a:hover .title:before {
    right: 0;
  }
  .teaser_overview .teaser a:hover .icon,
  .overview .teaser a:hover .icon,
  .teaser_overview .entry a:hover .icon,
  .overview .entry a:hover .icon {
    -webkit-transform: translateZ(0) scale(1.15);
            transform: translateZ(0) scale(1.15);
  }
  .teaser_overview .teaser .title,
  .overview .teaser .title,
  .teaser_overview .entry .title,
  .overview .entry .title {
    letter-spacing: calc((1.125rem + 0.625 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1.125rem;
    color: #222222;
    display: inline;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    width: 100%;
  }
  .teaser_overview .teaser .title:before,
  .overview .teaser .title:before,
  .teaser_overview .entry .title:before,
  .overview .entry .title:before {
    background: #FFCC00;
    bottom: 0;
    content: "";
    height: 0.125rem;
    left: 0;
    position: absolute;
    right: 100%;
    -webkit-transition: right 300ms ease-out;
    transition: right 300ms ease-out;
    z-index: -1;
  }
  .teaser_overview .teaser p,
  .overview .teaser p,
  .teaser_overview .entry p,
  .overview .entry p {
    margin-top: 1.25em;
    width: 100%;
  }
  .teaser_overview .teaser .icon,
  .overview .teaser .icon,
  .teaser_overview .entry .icon,
  .overview .entry .icon {
    display: block;
    height: 4.8125rem;
    margin: 0 auto 1.25em;
    -webkit-transition: 300ms;
    transition: 300ms;
    width: 4.8125rem;
  }
  .timeline {
    margin: 0 auto;
    position: relative;
    padding-top: 1.5em;
  }
  .timeline.animated .timeline_wrapper:before {
    bottom: 0;
  }
  .timeline.animated .timeline_entry .timeline_content .icon {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .timeline.animated .timeline_entry:before {
    -webkit-transform: translateX(-50%) translateY(100%) scale(1);
            transform: translateX(-50%) translateY(100%) scale(1);
  }
  .timeline .icon_down {
    display: inline-block;
    height: 1.375rem;
    margin-bottom: 1.25rem;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 0.875rem;
  }
  .timeline .timeline_wrapper {
    margin-left: 0;
    padding-left: 0;
    position: relative;
    padding-top: 1.5em;
  }
  .timeline .timeline_wrapper:before {
    background: #222222;
    bottom: 100%;
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: 1800ms;
    transition: 1800ms;
    width: 0.0625rem;
  }
  .timeline .timeline_entry {
    display: -webkit-box;
    display: flex;
    padding-left: 1.5em;
    position: relative;
    margin-bottom: 1.5em;
  }
  .timeline .timeline_entry:before {
    background: #222222;
    border-radius: 100%;
    content: "";
    height: 0.78571429rem;
    left: 0.0625rem;
    position: absolute;
    top: 3.375rem;
    -webkit-transform: translateX(-50%) translateY(100%) scale(0);
            transform: translateX(-50%) translateY(100%) scale(0);
    width: 0.78571429rem;
    z-index: 1;
  }
  .timeline .timeline_entry:nth-child(1):before {
    -webkit-transition: 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 500ms;
    transition: 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 500ms;
  }
  .timeline .timeline_entry:nth-child(1) .timeline_content .icon {
    -webkit-transition: 1200ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 500ms;
    transition: 1200ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 500ms;
  }
  .timeline .timeline_entry:nth-child(2):before {
    -webkit-transition: 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 1000ms;
    transition: 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 1000ms;
  }
  .timeline .timeline_entry:nth-child(2) .timeline_content .icon {
    -webkit-transition: 1200ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 1000ms;
    transition: 1200ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 1000ms;
  }
  .timeline .timeline_entry:nth-child(3):before {
    -webkit-transition: 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 1500ms;
    transition: 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 1500ms;
  }
  .timeline .timeline_entry:nth-child(3) .timeline_content .icon {
    -webkit-transition: 1200ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 1500ms;
    transition: 1200ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 1500ms;
  }
  .timeline .timeline_entry .timeline_content {
    position: relative;
  }
  .timeline .timeline_entry .timeline_content.last .text_wrapper {
    position: relative;
  }
  .timeline .timeline_entry .timeline_content.last .text_wrapper:before {
    background: #fff;
    content: "";
    height: 90%;
    position: absolute;
    bottom: 0;
    right: 100%;
    width: 6em;
  }
  .timeline .timeline_entry .timeline_content .title,
  .timeline .timeline_entry .timeline_content p {
    color: #4E4E4E;
    font-size: 1rem;
  }
  .timeline .timeline_entry .timeline_content .title {
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  .timeline .timeline_entry .timeline_content p {
    display: inline;
    margin: 0;
  }
  .timeline .timeline_entry .timeline_content .icon {
    display: block;
    height: 3rem;
    margin-bottom: 1em;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    width: 3rem;
  }
  .social_media_links ul {
    display: -webkit-box;
    display: flex;
    margin: 0;
    padding: 0;
    background: #000000;
    -webkit-box-pack: space-evenly;
            justify-content: space-evenly;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .social_media_links li {
    list-style: none;
    margin: 0;
    padding: 0.70833333rem 0 0.70833333rem 0.70833333rem;
  }
  .social_media_links li:last-child {
    padding: 0.70833333rem;
  }
  .social_media_links a {
    border-radius: 0;
    display: inline-block;
  }
  .social_media_links a .icon {
    display: block;
    fill: #fff;
    height: 2.125rem;
    width: 2.125rem;
  }
  .social_media_links a.facebook .icon,
  .social_media_links a.twitter .icon {
    border-radius: 0;
  }
  .some_wrapper {
    width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
    margin: 0.70833333rem 0;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  [role="tooltip"] {
    background: #FFCC00;
    border: 0.0625rem solid #FFCC00;
    border-radius: 0;
    bottom: 100%;
    color: #222222;
    font-size: 85%;
    font-weight: 300;
    line-height: 1.5;
    padding: 0.75em;
    position: absolute;
    right: 0;
    text-align: center;
    -webkit-transform: translateY(-0.3125rem);
            transform: translateY(-0.3125rem);
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #FFCC00;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #FFCC00;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
  .video_overview .entry,
  .video_infolists_overview .entry,
  .video_infolist_overview .entry,
  .video_tutorial_overview .entry {
    display: inline-block;
  }
  .video_overview .image,
  .video_infolists_overview .image,
  .video_infolist_overview .image,
  .video_tutorial_overview .image,
  .video_overview figure,
  .video_infolists_overview figure,
  .video_infolist_overview figure,
  .video_tutorial_overview figure {
    margin: 0.75em 0;
  }
  .video_overview .image img,
  .video_infolists_overview .image img,
  .video_infolist_overview .image img,
  .video_tutorial_overview .image img,
  .video_overview figure img,
  .video_infolists_overview figure img,
  .video_infolist_overview figure img,
  .video_tutorial_overview figure img {
    width: 100%;
  }
  .video_overview figcaption,
  .video_infolists_overview figcaption,
  .video_infolist_overview figcaption,
  .video_tutorial_overview figcaption {
    font-size: 100%;
  }
  .video_overview .video_play,
  .video_infolists_overview .video_play,
  .video_infolist_overview .video_play,
  .video_tutorial_overview .video_play {
    position: relative;
  }
  .video_overview .video_play .icon,
  .video_infolists_overview .video_play .icon,
  .video_infolist_overview .video_play .icon,
  .video_tutorial_overview .video_play .icon {
    fill: #FFCC00;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .video_wrapper {
    height: 0;
    margin-bottom: 1.5em;
    padding-top: 56.25%;
    position: relative;
  }
  .video_wrapper video,
  .video_wrapper iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .video_wrapper video::cue {
    background: #000000;
    color: #ffffff;
  }
  .video_preview {
    margin-bottom: 0.75em;
  }
  .video_preview .entry {
    display: -webkit-box;
    display: flex;
    margin-bottom: 1.5em;
  }
  .video_preview figure {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
    padding-right: 1.5em;
  }
  .video_preview .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .video_preview .video_play {
    position: relative;
  }
  .video_preview .video_play .icon {
    fill: #FFCC00;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_preview .video_play {
    position: relative;
  }
  .steuernews_preview .video_play .icon {
    fill: #FFCC00;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_navigation {
    margin: 1.5em 0;
  }
  .steuernews_navigation nav ul:after {
    clear: both;
  }
  .steuernews_navigation nav ul:after,
  .steuernews_navigation nav ul:before {
    content: "";
    display: table;
  }
  .steuernews_navigation nav li {
    float: left;
  }
  .steuernews_navigation nav li:first-child a {
    padding-left: 0;
  }
  .steuernews_navigation nav a {
    display: inline-block;
    line-height: 1.5;
    padding: 0 1.5em;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.u_online_svg_2_small {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.u_online_svg_2 {
    display: block;
  }
}
@media screen and (min-width: 47.5rem) {
  .u_online_2_headline {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5rem) {
  .u_online_text {
    font-size: 80%;
  }
}
@media screen and (min-width: 35rem) {
  .service_sidebar .title_overview {
    text-align: center;
  }
}
@media screen and (min-width: 61.875rem) {
  .service_sidebar .icon_down {
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
  }
}
@media screen and (min-width: 20rem) {
  .service_sidebar .service_sidebar_wrapper {
    padding-top: calc(1.5em + 0.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .service_sidebar .service_sidebar_wrapper {
    padding-top: 2.25em;
  }
}
@media screen and (min-width: 20rem) {
  .service_sidebar .onlinetools_popup_overview,
  .service_sidebar .download_overview,
  .service_sidebar .steuernews_issues,
  .service_sidebar .address,
  .service_sidebar .opening_times {
    margin-bottom: calc(1.5em + 3.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .service_sidebar .onlinetools_popup_overview,
  .service_sidebar .download_overview,
  .service_sidebar .steuernews_issues,
  .service_sidebar .address,
  .service_sidebar .opening_times {
    margin-bottom: 5.25em;
  }
}
@media screen and (min-width: 35rem) {
  .service_sidebar .onlinetools_popup_overview:before,
  .service_sidebar .download_overview:before,
  .service_sidebar .steuernews_issues:before,
  .service_sidebar .address:before,
  .service_sidebar .opening_times:before {
    margin-left: 0;
  }
}
@media screen and (min-width: 35rem) {
  .service_sidebar .onlinetools_popup_overview .entry_content,
  .service_sidebar .download_overview .entry_content,
  .service_sidebar .steuernews_issues .entry_content,
  .service_sidebar .address .entry_content,
  .service_sidebar .opening_times .entry_content {
    padding-left: 1.5em;
  }
}
@media screen and (min-width: 20rem) {
  .service_sidebar .onlinetools_popup_overview ul .entry .title .title_small,
  .service_sidebar .download_overview ul .entry .title .title_small,
  .service_sidebar .steuernews_issues ul .entry .title .title_small,
  .service_sidebar .address ul .entry .title .title_small,
  .service_sidebar .opening_times ul .entry .title .title_small {
    font-size: calc(0.9375rem + 0.0625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .service_sidebar .onlinetools_popup_overview ul .entry .title .title_small,
  .service_sidebar .download_overview ul .entry .title .title_small,
  .service_sidebar .steuernews_issues ul .entry .title .title_small,
  .service_sidebar .address ul .entry .title .title_small,
  .service_sidebar .opening_times ul .entry .title .title_small {
    font-size: 1rem;
  }
}
@media screen and (min-width: 20rem) {
  .service_sidebar .onlinetools_popup_overview ul .entry .title .title_big,
  .service_sidebar .download_overview ul .entry .title .title_big,
  .service_sidebar .steuernews_issues ul .entry .title .title_big,
  .service_sidebar .address ul .entry .title .title_big,
  .service_sidebar .opening_times ul .entry .title .title_big {
    font-size: calc(1rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .service_sidebar .onlinetools_popup_overview ul .entry .title .title_big,
  .service_sidebar .download_overview ul .entry .title .title_big,
  .service_sidebar .steuernews_issues ul .entry .title .title_big,
  .service_sidebar .address ul .entry .title .title_big,
  .service_sidebar .opening_times ul .entry .title .title_big {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .company_logo {
    width: calc(13.4375rem + 12.8125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo {
    width: 26.25rem;
  }
}
@media screen and (max-width: 44.9375rem) {
  .company_logo {
    margin: 0 auto 1em;
  }
}
@media screen and (min-width: 20rem) {
  .cookie_banner .close > span {
    min-height: calc(2.625rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .cookie_banner .close > span {
    min-height: 2.625rem;
  }
}
@media screen and (min-width: 20rem) {
  .footer_wrapper {
    margin-bottom: calc(3em + 3 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .footer_wrapper {
    margin-bottom: 6em;
  }
}
@media screen and (min-width: 100rem) {
  .footer_wrapper {
    -webkit-box-align: end;
            align-items: flex-end;
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 20rem) {
  body:not(.startpage) .footer_wrapper {
    margin-top: calc(3em + 3 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  body:not(.startpage) .footer_wrapper {
    margin-top: 6em;
  }
}
@media screen and (max-width: 99.9375rem) {
  .footer_address .footer_contact_wrapper {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 20rem) {
  .footer_address .footer_contact_wrapper .contact {
    margin-right: calc(0.75em + 1.0499999999999998 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .footer_address .footer_contact_wrapper .contact {
    margin-right: 1.8em;
  }
}
@media screen and (max-width: 54.9375rem) {
  .footer_address .footer_open_times {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 29.9375rem) {
  .footer_address .footer_open_times .footer_open_times_entry .entry_first,
  .footer_address .footer_open_times .footer_open_times_entry .entry_last {
    display: block;
  }
  .footer_address .footer_open_times .seperator {
    display: none;
  }
}
@media screen and (min-width: 100rem) {
  .related_links {
    margin-left: auto;
    margin-top: 0;
  }
}
@media screen and (min-width: 100rem) {
  .related_links ul {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
}
@media screen and (min-width: 20rem) {
  .button > span {
    min-height: calc(2.625rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button > span {
    min-height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .button .text {
    font-size: calc(0.875rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button .text {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .button:active > span,
  .button.active > span {
    min-height: calc(2.625rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button:active > span,
  .button.active > span {
    min-height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .button:active .text,
  .button.active .text {
    font-size: calc(0.875rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button:active .text,
  .button.active .text {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .travel_mode_entry {
    height: calc(2.75rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .travel_mode_entry {
    height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .travel_mode_radio > span {
    min-height: calc(2.625rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .travel_mode_radio > span {
    min-height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .travel_mode_radio .text {
    font-size: calc(0.875rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .travel_mode_radio .text {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .infowindow {
    max-width: calc(13.75rem + 8.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .infowindow {
    max-width: 22.5rem;
  }
}
@media screen and (min-width: 101.25rem) {
  header.header {
    position: relative;
  }
}
@media screen and (min-width: 45rem) {
  .header_top {
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-pack: justify;
            justify-content: space-between;
    padding-bottom: 1.8em;
  }
}
@media screen and (min-width: 45rem) {
  .header_top .button {
    display: -webkit-inline-box;
    display: inline-flex;
  }
}
@media screen and (max-width: 44.9375rem) {
  .header_top .mobile_menu_bar {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
  }
  .header_top .mobile_menu_bar .mobile_contact {
    margin-left: auto;
  }
  .header_top .mobile_menu_bar .mobile_contact a {
    display: inline-block;
    height: 2.75rem;
    padding: 0.75em;
    width: 2.75rem;
  }
}
@media screen and (min-width: 45rem) {
  .header_top .mobile_menu_bar .mobile_contact {
    display: none;
  }
}
@media screen and (min-width: 20rem) {
  .header_bottom .container {
    padding: 0 calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .header_bottom .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  @supports (padding-left: env(safe-area-inset-left)) {
    html.iphone .header_bottom .container {
      padding-left: max(calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)), env(safe-area-inset-left));
      padding-right: max(calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)), env(safe-area-inset-right));
    }
  }
}
@media screen and (min-width: 20rem) {
  .header_bottom .container {
    padding: 0 calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .header_bottom .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .header_bottom .image_content_overlay {
    padding-bottom: calc(0.75em + 1.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .header_bottom .image_content_overlay {
    padding-bottom: 2.25em;
  }
}
@media screen and (min-width: 20rem) {
  .header_bottom .slogan_box .slogan_small {
    font-size: calc(1.0625rem + 0.875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .header_bottom .slogan_box .slogan_small {
    font-size: 1.9375rem;
  }
}
@media screen and (min-width: 20rem) {
  .header_bottom .slogan_box .slogan_big {
    font-size: calc(1.25rem + 1.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .header_bottom .slogan_box .slogan_big {
    font-size: 2.625rem;
  }
}
@media screen and (min-width: 35rem) {
  .header_bottom .slogan_box .button {
    display: inline-block;
    max-width: 15.625rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_bottom .digi_button,
  .header_bottom .career_button {
    margin-bottom: 2.25em;
    margin-top: 0;
    margin-left: -0.6em;
  }
}
@media screen and (min-width: 75rem) {
  .header_bottom .digi_button,
  .header_bottom .career_button {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
}
@media screen and (min-width: 45rem) {
  .header_bottom .digi_button,
  .header_bottom .career_button {
    -webkit-box-align: end;
            align-items: flex-end;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex-grow: 1;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_bottom .career_button {
    margin-bottom: 0.75em;
  }
}
@media screen and (min-width: 93.75rem) {
  .header_bottom .career_button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 45rem) {
  .header_bottom .contact_box {
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_bottom .contact_box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
}
@media screen and (min-width: 75rem) {
  .header_bottom .contact_box {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
}
@media screen and (max-width: 61.8125rem) {
  .header_bottom .contact_box .contact_link {
    margin-right: 1.25em;
  }
}
@media screen and (min-width: 45rem) and (max-width: 61.8125rem) {
  .header_bottom .contact_box .contact_link {
    margin-top: 1.5em;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_bottom .contact_box .contact_link {
    margin-left: -0.6em;
  }
}
@media screen and (max-width: 74.9375rem) {
  .header_bottom .contact_box a {
    margin-top: 0.75em;
  }
}
@media screen and (min-width: 61.875rem) and (max-width: 74.9375rem) {
  .header_bottom .contact_box a {
    background: rgba(255, 255, 255, 0.9);
    position: relative;
    padding: 0 0.375em;
  }
  .header_bottom .contact_box a:before,
  .header_bottom .contact_box a:after {
    background: rgba(255, 255, 255, 0.9);
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
  }
  .header_bottom .contact_box a:before {
    height: 0.1875em;
    top: -0.1875em;
  }
  .header_bottom .contact_box a:after {
    height: 0.15em;
    bottom: -0.15em;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30rem) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35rem) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5rem) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875rem) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 75rem) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75rem) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30rem) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75rem) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 93.75rem) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 20rem) {
  .file {
    height: calc(2.75rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file {
    height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_name {
    line-height: calc(2.625rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_name {
    line-height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_name {
    height: calc(2.75rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_name {
    height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_button > span {
    min-height: calc(2.625rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_button > span {
    min-height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_button .text {
    font-size: calc(0.875rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_button .text {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .file [type="file"] {
    height: calc(2.75rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file [type="file"] {
    height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .file [type="file"]:active ~ .file_button > span {
    min-height: calc(2.625rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file [type="file"]:active ~ .file_button > span {
    min-height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .file [type="file"]:active ~ .file_button .text {
    font-size: calc(0.875rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file [type="file"]:active ~ .file_button .text {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 35rem) {
  .formular .button_wrapper {
    text-align: right;
  }
}
@media screen and (min-width: 20rem) {
  button {
    height: calc(2.75rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  button {
    height: 2.875rem;
  }
}
@media screen and (min-width: 35rem) {
  button {
    width: auto;
  }
}
@media screen and (min-width: 20rem) {
  .infolist_overview {
    padding-top: calc(1.5em + 3.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .infolist_overview {
    padding-top: 5.25em;
  }
}
@media screen and (min-width: 20rem) {
  .infolist_overview .entry {
    padding-bottom: calc(1.5em + 1.8000000000000003 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .infolist_overview .entry {
    padding-bottom: 3.3em;
  }
}
@media screen and (min-width: 20rem) {
  .infolist_overview .entry {
    padding-left: calc(1.5em + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .infolist_overview .entry {
    padding-left: 1.5em;
  }
}
@media screen and (min-width: 20rem) {
  .infolist_overview .entry {
    padding-right: calc(1.5em + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .infolist_overview .entry {
    padding-right: 1.5em;
  }
}
@media screen and (min-width: 20rem) {
  .infolist_overview .entry {
    padding-top: calc(1.5em + 1.8000000000000003 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .infolist_overview .entry {
    padding-top: 3.3em;
  }
}
@media screen and (min-width: 20rem) {
  .infolist_overview .entry .title {
    font-size: calc(1.125rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .infolist_overview .entry .title {
    font-size: 1.75rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .multi_columns {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 3em;
            column-gap: 3em;
  }
}
@media screen and (min-width: 20rem) {
  @supports (padding-left: env(safe-area-inset-left)) {
    html.iphone .container {
      padding-left: max(calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)), env(safe-area-inset-left));
      padding-right: max(calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)), env(safe-area-inset-right));
    }
  }
}
@media screen and (min-width: 20rem) {
  body {
    margin-left: calc(0.75em + 2.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  body {
    margin-left: 3em;
  }
}
@media screen and (min-width: 20rem) {
  .startpage h2 {
    font-size: calc(1.3125rem + 1.0625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage h2 {
    font-size: 2.375rem;
  }
}
@media screen and (min-width: 20rem) {
  main {
    padding-top: calc(1.5em + 3 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  main {
    padding-top: 4.5em;
  }
}
@media screen and (min-width: 20rem) {
  .startpage_introduction {
    padding-bottom: calc(1.5em + 3 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage_introduction {
    padding-bottom: 4.5em;
  }
}
@media screen and (min-width: 20rem) {
  .startpage_introduction {
    padding-top: calc(1.5em + 1.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage_introduction {
    padding-top: 3em;
  }
}
@media screen and (min-width: 20rem) {
  .startpage_introduction .title_small {
    margin-bottom: calc(1.5em + 0.4500000000000002 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage_introduction .title_small {
    margin-bottom: 1.95em;
  }
}
@media screen and (min-width: 20rem) {
  .startpage_introduction .description,
  .startpage_introduction .title_small {
    font-size: calc(0.9375rem + 0.3125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage_introduction .description,
  .startpage_introduction .title_small {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .startpage_introduction .description {
    margin-bottom: calc(1.5em + 0.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage_introduction .description {
    margin-bottom: 2.25em;
  }
}
@media screen and (min-width: 75rem) {
  .startpage_introduction .description {
    margin-right: 18%;
  }
}
@media screen and (min-width: 61.875rem) {
  .toggle_offcanvas_menu label .icon {
    width: 2.625rem;
  }
}
@media screen and (min-width: 20rem) {
  .header_offcanvas_menu_wrapper {
    margin-left: calc(-0.75em + -2.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .header_offcanvas_menu_wrapper {
    margin-left: -3em;
  }
}
@media screen and (min-width: 47.5rem) {
  .header_offcanvas_menu_wrapper .top_wrapper {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    margin-left: 0.75em;
  }
}
@media screen and (min-width: 75rem) {
  .header_offcanvas_menu_wrapper .top_wrapper {
    padding-left: 3em;
  }
}
@media screen and (min-width: 47.5rem) {
  .header_offcanvas_menu_wrapper .close_button {
    -webkit-box-align: center;
            align-items: center;
    color: #fff;
    display: -webkit-box;
    display: flex;
    font-weight: 600;
    margin-right: 1.5em;
  }
  .header_offcanvas_menu_wrapper .close_button:hover,
  .header_offcanvas_menu_wrapper .close_button:focus {
    cursor: pointer;
  }
  .header_offcanvas_menu_wrapper .close_button .icon {
    display: inline-block;
    fill: #fff;
    height: 2.75rem;
    margin-right: 1em;
    width: 2.75rem;
  }
}
@media screen and (min-width: 47.5rem) and (min-width: 20rem) {
  .header_offcanvas_menu_wrapper .close_button {
    margin-right: calc(1.5em + 4.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 47.5rem) and (min-width: 93.75rem) {
  .header_offcanvas_menu_wrapper .close_button {
    margin-right: 6em;
  }
}
@media screen and (min-width: 47.5rem) {
  .offcanvas_mobile_close_button {
    display: none;
  }
}
@media screen and (max-width: 101.1875rem) {
  .offcanvas_menu_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(34, 34, 34, 0.5);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 240ms, visibility 240ms;
    transition: opacity 240ms, visibility 240ms;
    visibility: hidden;
    z-index: 110;
  }
}
@media screen and (min-width: 101.25rem) {
  .offcanvas_menu_overlay {
    background: rgba(34, 34, 34, 0);
  }
}
@media screen and (min-width: 30rem) {
  .offcanvas_menu {
    width: 21.875rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .offcanvas_menu {
    width: 31.5625rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .offcanvas_menu {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    width: 41.25rem;
  }
}
@media screen and (min-width: 75rem) {
  .offcanvas_menu {
    width: 41.25rem;
  }
}
@media screen and (min-width: 101.25rem) {
  .offcanvas_menu {
    width: 47.5rem;
  }
}
@media screen and (min-width: 101.25rem) {
  .offcanvas_menu .offcanvas_menu_content {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex-grow: 1;
    padding-left: 5%;
  }
}
@media screen and (min-width: 75rem) {
  .offcanvas_menu .offcanvas_menu_content {
    padding-left: 15%;
  }
}
@media screen and (min-width: 30rem) {
  .offcanvas_menu .offcanvas_menu_content > ul {
    width: 21.875rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .offcanvas_menu .offcanvas_menu_content > ul {
    width: 31.5625rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .offcanvas_menu .offcanvas_menu_content > ul {
    width: 41.25rem;
  }
}
@media screen and (min-width: 75rem) {
  .offcanvas_menu .offcanvas_menu_content > ul {
    width: 41.25rem;
  }
}
@media screen and (min-width: 101.25rem) {
  .offcanvas_menu .offcanvas_menu_content > ul {
    width: 47.5rem;
  }
}
@media screen and (max-width: 74.9375rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    padding: 0 1.5em;
  }
}
@media screen and (min-width: 20rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    font-size: calc(1.125rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    line-height: 1.9;
  }
}
@media screen and (min-width: 101.25rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    line-height: 2.1;
  }
}
@media screen and (min-width: 61.875rem) {
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: -webkit-box !important;
    display: flex !important;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 20rem) {
  .open_street_map .map {
    height: calc(21.875rem + 12.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .open_street_map .map {
    height: 34.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .saddr {
    margin: 0 1.5em 0 0;
    max-width: 40.625rem;
  }
  .open_street_map .plan_route .saddr input {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper {
    margin: 0 1.5em 0 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper .select {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .button_wrapper button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 20rem) {
  .popup_close {
    height: calc(2.75rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_close {
    width: calc(2.75rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    width: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_content .content {
    top: calc(2.75rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_content .content {
    top: 2.875rem;
  }
}
@media screen and (min-width: 45rem) {
  .toggle_search_box a .icon {
    height: 2.125rem;
    width: 2.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .search_box form[role="search"] button {
    width: calc(2.75rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .search_box form[role="search"] button {
    width: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  form[role="search"] button > span {
    min-height: calc(2.625rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  form[role="search"] button > span {
    min-height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  form[role="search"] button .text {
    font-size: calc(0.875rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  form[role="search"] button .text {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 20rem) {
  form[role="search"] button {
    width: calc(2.75rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  form[role="search"] button {
    width: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  form[role="search"] button .icon {
    height: calc(2.625rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  form[role="search"] button .icon {
    height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  input[type="search"] {
    height: calc(2.75rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  input[type="search"] {
    height: 2.875rem;
  }
}
@media screen and (max-width: 29.9375rem) {
  input[type="search"] {
    width: 100%;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_preview {
    margin-top: calc(1.5em + 4.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_preview {
    margin-top: 6em;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_preview {
    padding-bottom: calc(1.5em + 3.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_preview {
    padding-bottom: 5.25em;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_preview {
    padding-top: calc(1.5em + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_preview {
    padding-top: 1.5em;
  }
}
@media screen and (min-width: 20rem) {
  body:not(.news) .steuernews_preview {
    padding-top: calc(1.5em + 3.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  body:not(.news) .steuernews_preview {
    padding-top: 5.25em;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_preview .title_small {
    margin-bottom: calc(1.5em + 1.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_preview .title_small {
    margin-bottom: 3em;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_article_slider .slide {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_article_slider .slide .description {
    padding: 0 4.5em 0 3em;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_article_slider .control_slides {
    top: 50%;
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .entry {
    -webkit-box-align: start;
            align-items: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
}
@media screen and (min-width: 20rem) {
  .teaser_overview {
    padding-bottom: calc(1.5em + 3.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser_overview {
    padding-bottom: 5.25em;
  }
}
@media screen and (min-width: 20rem) {
  .teaser_overview {
    padding-top: calc(1.5em + 6 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser_overview {
    padding-top: 7.5em;
  }
}
@media screen and (min-width: 20rem) {
  .teaser_overview .teaser,
  .overview .teaser,
  .teaser_overview .entry,
  .overview .entry {
    padding-bottom: calc(1.5em + 1.8000000000000003 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser_overview .teaser,
  .overview .teaser,
  .teaser_overview .entry,
  .overview .entry {
    padding-bottom: 3.3em;
  }
}
@media screen and (min-width: 20rem) {
  .teaser_overview .teaser,
  .overview .teaser,
  .teaser_overview .entry,
  .overview .entry {
    padding-left: calc(1.5em + 0.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser_overview .teaser,
  .overview .teaser,
  .teaser_overview .entry,
  .overview .entry {
    padding-left: 2.25em;
  }
}
@media screen and (min-width: 20rem) {
  .teaser_overview .teaser,
  .overview .teaser,
  .teaser_overview .entry,
  .overview .entry {
    padding-right: calc(1.5em + 0.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser_overview .teaser,
  .overview .teaser,
  .teaser_overview .entry,
  .overview .entry {
    padding-right: 2.25em;
  }
}
@media screen and (min-width: 20rem) {
  .teaser_overview .teaser,
  .overview .teaser,
  .teaser_overview .entry,
  .overview .entry {
    padding-top: calc(1.5em + 1.8000000000000003 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser_overview .teaser,
  .overview .teaser,
  .teaser_overview .entry,
  .overview .entry {
    padding-top: 3.3em;
  }
}
@media screen and (min-width: 20rem) {
  .teaser_overview .teaser .title,
  .overview .teaser .title,
  .teaser_overview .entry .title,
  .overview .entry .title {
    font-size: calc(1.125rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser_overview .teaser .title,
  .overview .teaser .title,
  .teaser_overview .entry .title,
  .overview .entry .title {
    font-size: 1.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .timeline {
    padding-top: calc(1.5em + 1.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .timeline {
    padding-top: 3em;
  }
}
@media screen and (min-width: 35rem) {
  .timeline .title_overview {
    text-align: center;
  }
}
@media screen and (min-width: 61.875rem) {
  .timeline .icon_down {
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
  }
}
@media screen and (min-width: 20rem) {
  .timeline .timeline_wrapper {
    padding-top: calc(1.5em + 0.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .timeline .timeline_wrapper {
    padding-top: 2.25em;
  }
}
@media screen and (min-width: 20rem) {
  .timeline .timeline_entry {
    margin-bottom: calc(1.5em + 3.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .timeline .timeline_entry {
    margin-bottom: 5.25em;
  }
}
@media screen and (min-width: 35rem) {
  .timeline .timeline_entry:before {
    margin-left: 0;
  }
}
@media screen and (min-width: 35rem) {
  .timeline .timeline_entry .timeline_content {
    padding-left: 1.5em;
  }
}
@media screen and (min-width: 30rem) {
  .some_wrapper {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}
@media print {
  .company_logo {
    display: block;
    margin-bottom: 1.5em;
    max-width: 17.5rem !important;
  }
  .company_logo img {
    display: block;
  }
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    min-width: 5.3125rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  .google_maps .distance::before {
    content: " - ";
    display: inline;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.5em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 600;
    margin-bottom: 0.75em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  body {
    display: block;
    font-size: 85%;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.5em;
  }
  .qr_code_print {
    border-top: 0.0625rem solid;
    margin: 1.5em 0 0;
    padding: 0.75em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    border: 0.0625rem solid black;
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
